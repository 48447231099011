const locale = {
	MANIFESTS: "Manifests",
	MANIFEST: "Manifest",
	MANIFEST_COMPOSE: "Add new manifest",
	PROJECTS: "Projects",
	PROJECT: "Project",
	PROJECT_COMPOSE: "Add new project",
	DELIVERIES: "Deliveries",
	DELIVERY: "Delivery",
	FOLDERS: "Mailboxes",
	FILTERS: "Filters",
	LABELS: "Labels",
	NO_MESSAGES: "There are no messages!",
	SEARCH_PLACEHOLDER: "Search for an e-mail or task",
	GENERAL_DASHBOARD: "Canon Consip3 - Logistics",
}

export default locale
