const locale = {
	MANIFESTS: "Manifest",
	MANIFEST: "Manifest",
	MANIFEST_COMPOSE: "Aggiungi nuovo manifets",
	PROJECTS: "Progetti",
	PROJECT: "Progetto",
	PROJECT_COMPOSE: "Aggiungi nuovo progetto",
	DELIVERIES: "Consegne",
	DELIVERY: "Consegna",
	FOLDERS: "Cartelle",
	FILTERS: "Filtri",
	LABELS: "Etichette",
	NO_MESSAGES: "Non ci sono messaggi!",
	SEARCH_PLACEHOLDER: "Cerca una email o attività",
	GENERAL_DASHBOARD: "Canon Consip3 - Logistica",
}

export default locale
