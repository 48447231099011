import FuseUtils from "@fuse/utils"
import AppContext from "app/AppContext"
import { Component } from "react"
import { matchRoutes } from "react-router-dom"
import withRouter from "@fuse/core/withRouter"
import history from "@history"
import { getSessionRedirectUrl, setSessionRedirectUrl, resetSessionRedirectUrl } from "@fuse/core/FuseAuthorization/sessionRedirectUrl"
import { ignoredPaths } from "app/configs/ignoredPaths"
const debug = false
let ConstructorCount = 0

class FuseAuthorization extends Component {
	constructor(props, context) {
		debug && console.log(`<FuseAuthorization> Constructor(${++ConstructorCount})`)
		super(props)
		const { routes } = context

		this.state = {
			accessGranted: true,
			routes,
		}
	}

	/**
	 * The componentDidMount() method is called after the component is rendered (Mounted).
	 */
	componentDidMount() {
		debug && console.log(`<FuseAuthorization> componentDidMount(${ConstructorCount}):`, " -accessGranted:", this.state.accessGranted)
		if (!this.state.accessGranted) {
			this.redirectRoute()
		}
	}

	/**
	 * The componentDidUpdate method is called after the component is updated in the DOM.
	 */
	componentDidUpdate() {
		debug && console.log("<FuseAuthorization> componentDidUpdate() -accessGranted: " + this.state.accessGranted)
		if (!this.state.accessGranted) {
			this.redirectRoute()
		}
	}

	/**
	 * In the shouldComponentUpdate() method you can return a Boolean value
	 * that specifies whether React should continue with the rendering or not.
	 * The default value is true.
	 * @param {*} nextProps
	 * @param {*} nextState
	 * @returns
	 */
	shouldComponentUpdate(nextProps, nextState) {
		debug && console.log(`<FuseAuthorization> shouldComponentUpdate(${ConstructorCount}) -return:`, nextState.accessGranted !== this.state.accessGranted)
		return nextState.accessGranted !== this.state.accessGranted
	}

	/**
	 * getDerivedStateFromProps(props, state) is a static method that is called
	 * just before render() method in both mounting and updating phase
	 * @param {*} props
	 * @param {*} state
	 * @returns
	 */
	static getDerivedStateFromProps(props, state) {
		const { location, userRole } = props
		const { pathname } = location
		debug && console.log(`<FuseAuthorization> getDerivedStateFromProps(${ConstructorCount})`)

		const matchedRoutes = matchRoutes(state.routes, pathname)
		const matched = matchedRoutes ? matchedRoutes[0] : false

		const userHasPermission = FuseUtils.hasPermission(matched.route.auth, userRole)
		debug && console.log(`<FuseAuthorization> getDerivedStateFromProps(${ConstructorCount}) -pathname:${pathname} -userHasPermission:${userHasPermission} -ignored:${ignoredPaths.includes(pathname)}`)

		if (matched && !userHasPermission && !ignoredPaths.includes(pathname)) {
			debug && console.log(`<FuseAuthorization> setSessionRedirectUrl(${pathname})`)
			setSessionRedirectUrl(pathname)
		}

		debug && console.log(`<FuseAuthorization> getDerivedStateFromProps(${ConstructorCount}) -return:`, { accessGranted: matched ? userHasPermission : true })
		return {
			accessGranted: matched ? userHasPermission : true,
		}
	}

	redirectRoute() {
		debug && console.log(`<FuseAuthorization> -redirectRoute()`)
		const { userRole, userActive } = this.props
		let redirectUrl = getSessionRedirectUrl() //|| this.props.loginRedirectUrl
		debug && console.log(`<FuseAuthorization> -redirectRoute() -userRole:${userRole} -redirectUrl:${redirectUrl}`)

		if (!userRole || userRole.length === 0) {
			/*
			User is guest
			Redirect to Login Page */
			debug && console.log("<FuseAuthorization> -redirectRoute() - (guest) Redirect to: /sign-in")
			setTimeout(() => history.push("/sign-in"), 0)
		} else if (userRole && userRole === "prospect" && !userActive) {
			/*
			User is prospect
			Redirect to Sign-up Page */
			debug && console.log("<FuseAuthorization> -redirectRoute() - (prospect not active) Redirect to: /sign-on")
			setTimeout(() => history.push("/sign-on"), 0)
		} else if (userRole && userRole === "prospect" && userActive) {
			/*
			User is prospect and active
			Redirect to Sign-up Page */
			debug && console.log("<FuseAuthorization> -redirectRoute() - (prospect active) Redirect to: /sign-up")
			setTimeout(() => history.push("/sign-up"), 0)
		} else {
			/*
			 User is member
			 User must be on unAuthorized page or just logged in
			 Redirect to dashboard or loginRedirectUrl */
			debug && console.log("<FuseAuthorization> -redirectRoute() - (member) Redirect to: " + redirectUrl)
			setTimeout(() => history.push(redirectUrl), 0)

			resetSessionRedirectUrl()
		}
	}

	render() {
		debug && console.log(`<FuseAuthorization> render(${ConstructorCount}): ${this.state.accessGranted}`)
		return this.state.accessGranted ? this.props.children : null
	}
}

FuseAuthorization.contextType = AppContext

export default withRouter(FuseAuthorization)
