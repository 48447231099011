import { lazy } from "react"
import authRoles from "../../../auth/authRoles"
const ELogisticsDashboardApp = lazy(() => import("./ELogisticsDashboardApp"))

const ELogisticsDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.staff,
	routes: [
		{
			path: "dashboards/e-logistics-general",
			element: <ELogisticsDashboardApp />,
		},
	],
}

export default ELogisticsDashboardAppConfig
