import AnalyticsDashboardAppConfig from "./analytics/AnalyticsDashboardAppConfig"
import ProjectDashboardAppConfig from "./project/ProjectDashboardAppConfig"
import FinanceDashboardAppConfig from "./finance/FinanceDashboardAppConfig"
import SysemDashboardAppConfig from "./system/SystemDashboardAppConfig"
import CryptoDashboardAppConfig from "./crypto/CryptoDashboardAppConfig"
import ELogisticsDashboardAppConfig from "./e-logistic/ELogisticsDashboardAppConfig"

const dashboardsConfigs = [SysemDashboardAppConfig, AnalyticsDashboardAppConfig, ProjectDashboardAppConfig, FinanceDashboardAppConfig, CryptoDashboardAppConfig, ELogisticsDashboardAppConfig]

export default dashboardsConfigs
