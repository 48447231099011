import FuseUtils from "@fuse/utils"
import { useSelector } from "react-redux"
import FuseLoading from "@fuse/core/FuseLoading"
import { Navigate } from "react-router-dom"
import settingsConfig from "app/configs/settingsConfig"
import userInterfaceConfigs from "../main/user-interface/UserInterfaceConfigs"
import SignInConfig from "../main/sign-in/SignInConfig"
import SignUpConfig from "../main/sign-up/SignUpConfig"
import SignOutConfig from "../main/sign-out/SignOutConfig"
import dashboardsConfigs from "../main/dashboards/dashboardsConfigs"
import appsConfigs from "../main/apps/appsConfigs"
import pagesConfigs from "../main/pages/pagesConfigs"
import authRoleExamplesConfigs from "../main/auth/authRoleExamplesConfigs"
import DocumentationConfig from "../main/documentation/DocumentationConfig"

const routeConfigs = [...appsConfigs, ...dashboardsConfigs, ...pagesConfigs, ...authRoleExamplesConfigs, ...userInterfaceConfigs, DocumentationConfig, SignOutConfig, SignInConfig, SignUpConfig]

const DynamicRedirect = () => {
	const user = useSelector((state) => state.user)
	const redirectUrl = user?.loginRedirectUrl || settingsConfig.loginRedirectUrl
	return <Navigate to={redirectUrl} />
}

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
	{
		path: "/",
		//element: <ProjectDashboardApp />, // test SPA without route path. no buono per tabs
		//element: <Navigate to="dashboards/project" />, //this should be public home page
		//element: <Navigate to={settingsConfig.loginRedirectUrl} />, //dynamic non funge
		element: <DynamicRedirect />, //dynamic from user store
		auth: settingsConfig.defaultAuth,
	},
	{
		path: "loading",
		element: <FuseLoading />,
	},
	{
		path: "*",
		element: <Navigate to="pages/error/404" />,
	},
]

export default routes
